<template>
  <a-spin :spinning="spinning">
    <div class="head-bar">
      <div class="back">
        <a-button @click="goBack"> <a-icon type="left" />返回</a-button>
      </div>
      <h1>合同类型设置</h1>
    </div>
    <div class="detail">
      <div class="detail-head">
        <div class="ban">
          <!-- <div class="wrapper"> -->
            <div class="w-l">
              <p>固定期限劳动合同</p>
              <p>适用于全职员工签订固定期限劳动合同</p>
            </div>
            <div class="w-r">
              <a-button @click="initInputModal">编辑</a-button>
            </div>
          <!-- </div> -->
        </div>
        <div class="tags">
          <div><router-link class="tag"  to="sign">签署设置</router-link></div>
          <div><router-link class="tag"  to="templete">合同模版</router-link></div>
          <div><router-link class="tag"  to="archiveSettings">归档设置</router-link></div>
        </div>
      </div>
    </div>

    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    > 
      <div>
        <a-form-model :model="inputModalParams.form" :label-col="{span: 6}" :wrapper-col="{span: 16}">
          <a-form-model-item label="合同类型名称">
            <a-input v-model="inputModalParams.form.cName" placeholder="请输入" />
          </a-form-model-item>

          <a-form-model-item label="选择分组">
            <a-select v-model="inputModalParams.form.group" placeholder="请选择">
              <a-select-option :value="item.value" v-for="(item, index) in inputModalParams.form.groupList" :key="index">
                {{item.desc}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="备注">
            <a-input v-model="inputModalParams.form.remark" type="textarea" placeholder="请输入" />
          </a-form-model-item>

        </a-form-model>
      </div>
    </a-modal>


    <router-view></router-view>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";

export default {
  mixins: [AppComponentBase],
    name: "seal-manage",
    data() {
      return {
        spinning: false,
        inputModalParams: {
          destroyOnClose: true,
          maskClosable: false,
          confirmLoading: false,
          visible: false,
          width: 620,
          title: '',
          cancelText: '取消',
          okText: '确认',
          form: {
            cName: '',
            remark: '',
            group: null,
            groupList: [{value: '1', desc: '1'}, {value: '2', desc: '2'}]
          },
          cancelText: '取消',
          okText: '确认',
          confirm: () => {},
          cancel: () => {
            this.inputModalParams.visible = false
          }
        },
      }
    },
    created() {

    },
    mounted() {
  
    },
    methods: {
      initInputModal() {
        let _this = this
        this.inputModalParams.title = '新增合同类型'
        this.inputModalParams.okText = '确认'
        this.inputModalParams.confirm = function() {
          // Do something...
          _this.inputModalParams.visible = false
        }
        this.inputModalParams.visible = true
      },
      goBack() {
        this.$router.push({
          path: `/app/agreement/category`
        })
      }
    },
    components: {

    }
}
</script>

<style lang="less" scope>
@import "../../../styles/common.less";
.head-bar{
  min-height: 60px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,.08);
  position: relative;
  .back{
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
  }
  h1{
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    min-height: 60px;
    line-height: 60px;
  }
}
.detail{
  .detail-head{
    .ban{
      width: 80%;
      min-width: 800px;
      margin: 30px auto;
      padding: 32px;
      #border-radius(6px);
      #flex;
      #flex-jcsb;
      background-color: @color-white;
      box-shadow: 0 4px 16px rgba(225,230,235,.5);
      .w-l{
        p{
          color: @color-lightblack;
          margin-bottom: 0;
          #fs16;
        }
        p:first-of-type{
          color: @color-black;
          #fs28;
          #mrb10;
        }
      }
      .w-r{

      }
    }
    .tags{
      padding: 10px 0;
      margin: 0 auto;
      #flex;
      width: 10%;
      min-width: 500px;

      div{
        #tac;
        #flex1;
        .tag{
          #dib;
          color: @color-black;
          margin: 0 16px;
          padding: 0 16px;
          height: 32px;
          line-height: 32px;
          min-width: 90px;
          &:hover{
            color: @color-blue;
          }
          &.router-link-active{
            color: @color-blue;
            border: 1px solid @color-blue;
            #border-radius(16px);
          }
        }
      }
    }
  }
}

</style>
